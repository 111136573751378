import Img from 'gatsby-image';
import Title from '../typography/Title';
import Text from '../typography/Text';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../styles/config';
import { FluidDatoCmsImage } from '../../models/DatoCms';
import { RemarkNode } from '../../models/RemarkNode';

const AboutGrid = styled.div`
    @media (min-width: ${breakpoints.tablet}px) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        margin: 0 -1rem;
    }
`;

const AboutColumn = styled.div`
    @media (max-width: ${breakpoints.tablet - 0.02}px) {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    @media (min-width: ${breakpoints.tablet}px) {
        flex: none;
        width: 50%;
        padding: 1rem;
    }
`;

export interface AboutProps {
    title: string;
    aboutNode: RemarkNode;
    presentationImage: FluidDatoCmsImage;
}

export default function About({ presentationImage, title, aboutNode }: AboutProps): JSX.Element {
    return (
        <AboutGrid>
            <AboutColumn>
                <Img fluid={presentationImage.fluid} />
            </AboutColumn>
            <AboutColumn>
                <Title level={1}>{title}</Title>
                <Text dangerouslySetSanitizedHTML={aboutNode.childMarkdownRemark.html} />
            </AboutColumn>
        </AboutGrid>
    );
}
