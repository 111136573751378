import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import styled from 'styled-components';
import { Section } from '../components/Block';
import { Main } from '../components/Main';
import { RemarkNode } from '../models/RemarkNode';
import { FluidDatoCmsImage } from '../models/DatoCms';
import AboutQuote from '../components/about/AboutQuote';
import ListGallery, { ListGalleryImage } from '../components/gallery/ListGallery';
import About from '../components/about/About';
import Title from '../components/typography/Title';
import Subtitle from '../components/typography/Subtitle';

const Container = styled(Section)``;

export interface AboutPageProps {
    data: {
        datoCmsAbout: {
            seoMetaTags: any;
            title: string;
            aboutNode: RemarkNode;
            presentationImage: FluidDatoCmsImage;
            quoteText: string;
            quoteAuthor: string;
            certificates: ListGalleryImage[];
            certificatesTitle: string;
            certificatesSubtitle: string;
        };
    };
}

export default function AboutPage({ data }: AboutPageProps): JSX.Element {
    const {
        title,
        aboutNode,
        presentationImage,
        quoteText,
        quoteAuthor,
        certificates,
        certificatesTitle,
        certificatesSubtitle,
        seoMetaTags,
    } = data.datoCmsAbout;
    return (
        <>
            <Container withGradient as={Main}>
                <HelmetDatoCms seo={seoMetaTags} />
                <About title={title} aboutNode={aboutNode} presentationImage={presentationImage} />
                <AboutQuote text={quoteText} author={quoteAuthor} />
            </Container>
            <Section withGradient largeSpacing>
                <Title level={2}>{certificatesTitle}</Title>
                <Subtitle>{certificatesSubtitle}</Subtitle>
                <ListGallery images={certificates} />
            </Section>
        </>
    );
}

export const query = graphql`
    query AboutPage($language: String!) {
        datoCmsAbout(locale: { eq: $language }) {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            title
            aboutNode {
                ...RemarkNode
            }
            presentationImage {
                fluid(maxWidth: 700) {
                    ...GatsbyDatoCmsFluid
                }
            }
            quoteText
            quoteAuthor
            certificatesTitle
            certificatesSubtitle
            certificates {
                ...ListGalleryImage
            }
        }
    }
`;
