import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/config';

const Container = styled.section`
    padding: 1rem;
    border-radius: 0.5rem;
    background: ${colors.grey6};
`;

export interface AboutQuoteProps {
    author: string;
    text: string;
}

export default function AboutQuote({ author, text }: AboutQuoteProps): JSX.Element | null {
    return null;
}
