import React, { useCallback } from 'react';
import { graphql } from 'gatsby';
import Img, { FixedObject, FluidObject } from 'gatsby-image';
import styled from 'styled-components';
import ImageModal from './ImageModal';
import useImageGallery from './useImageGallery';
import { useKeydown } from '../util/useKeydown';
import GalleryImageOverlay from './GalleryImageOverlay';

// STYLES -----------------------------------------------

const THUMBNAIL_WIDTH = 248;
const THUMBNAIL_HEIGHT = 148;
const GALLERY_GAP = 16;
const THUMBNAIL_CONTAINER_WIDTH = THUMBNAIL_WIDTH + GALLERY_GAP * 2;

const GalleryContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -${GALLERY_GAP};

    @media (max-width: ${THUMBNAIL_CONTAINER_WIDTH * 2}px) {
        flex-direction: column;
    }
`;

const ThumbnailContainer = styled.div`
    position: relative;
    padding: 0 ${GALLERY_GAP}px;
    margin-bottom: ${GALLERY_GAP}px;
    width: ${THUMBNAIL_CONTAINER_WIDTH}px;
    height: ${THUMBNAIL_HEIGHT}px;
    flex: none;
`;

const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const ThumbnailOverlay = styled(GalleryImageOverlay)`
    left: ${GALLERY_GAP}px;
    width: calc(100% - ${GALLERY_GAP * 2}px);
`;

// ------------------------------------------------------

export interface ListGalleryImage {
    originalId: string;
    alt: string | null;
    image: FluidObject | FluidObject[];
    thumbnail: FixedObject | FixedObject[];
}

export interface ListGalleryProps {
    images: ListGalleryImage[];
}

export default function ListGallery({ images }: ListGalleryProps) {
    const { image, setImage, prev, next } = useImageGallery(images);
    const close = useCallback(() => setImage(null), []);

    return (
        <div>
            <GalleryContainer>
                {images.map(image => (
                    <ThumbnailContainer key={image.originalId}>
                        <ImageContainer>
                            <Img fixed={image.thumbnail} />
                        </ImageContainer>
                        <ThumbnailOverlay alt={image.alt} onClick={() => setImage(image)} />
                    </ThumbnailContainer>
                ))}
            </GalleryContainer>
            {image && <ImageModal image={{ fluid: image.image }} prev={prev} next={next} onClose={close} />}
        </div>
    );
}

export const imageFragment = graphql`
    fragment ListGalleryImage on DatoCmsFileField {
        originalId
        alt
        image: fluid(maxWidth: 1366) {
            ...GatsbyDatoCmsFluid_noBase64
        }
        thumbnail: fixed(width: 248) {
            ...GatsbyDatoCmsFixed
        }
    }
`;
