import { useCallback, useState } from 'react';

export default function useImageGallery<T>(
    images: T[]
): {
    image: T | null;
    setImage: (image: T | null) => void;
    prev: () => void;
    next: () => void;
} {
    const [imageIndex, setImageIndex] = useState<number | null>(null);

    const moveImage = (modifier: number) => () => {
        setImageIndex(index => {
            if (index === null) return null;
            return (images.length + index + modifier) % images.length;
        });
    };
    const prev = useCallback(moveImage(-1), [images]);
    const next = useCallback(moveImage(1), [images]);

    const setImage = useCallback(
        (image: T | null) => {
            if (image === null) return setImageIndex(null);
            const index = images.indexOf(image);
            return setImageIndex(index !== -1 ? index : null);
        },
        [images]
    );

    return {
        image: imageIndex !== null ? images[imageIndex] : null,
        prev,
        next,
        setImage,
    };
}
